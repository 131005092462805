.register {
    margin-top: 36px;

    .btn {
        border-radius: 36px;
        border: 1px solid var(--secondary-green);
        width: 100%;
        padding: 16px 32px;
    }

    .btn2 {
        background: var(--secondary-green);
        padding: 16px 32px;
        border-radius: 36px;
        height: 61px;
        display: block;
        width: 100%;
        color: var(--text-white);

    }

    .bottom_text {
        margin-top: 48px;
        margin-bottom: 96px;
        text-align: center;
        font-size: 20px;
        font-style: normal;
        font-weight: 400;
        line-height: normal;

        a {
            color: var(--secondary-green)!important;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: normal;
        }
    }
}